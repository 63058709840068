<template>

  <v-app style="background-color:#f8f9fb">

    <!-- Diálogo de progresso -->
    <v-dialog v-model="showProgress" persistent max-width="300">
      <v-card>
        <v-card-title>
          Baixando arquivo...
        </v-card-title>
        <v-card-text>
          <!-- Coloque aqui qualquer indicador de progresso que você deseja mostrar -->
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-container>
      <v-col cols="12">
        <filtro-component @filtrar="filterData"></filtro-component>
      </v-col>
    </v-container>

    <v-row class="px-4">
      <v-col cols="12" sm="4" lg="4">
        <base-material-stats-card v-if="!card1_loading" color="green" icon="mdi-file-document-check-outline"
          title="Total de Vendas" sub-icon-color="blue" :valuex='false' :value='cards.vendas'
          :sub-text="'Total Ativos: ' + cards.ativas" />
        <CardLoadComponent v-if="card1_loading" />
      </v-col>

      <v-col cols="12" sm="4" lg="4">
        <base-material-stats-card v-if="!card2_loading" color="red" icon="mdi-cancel" title="Total Cancelados"
          sub-icon-color="white" :value='cards.canceladas' :valuex='false'
          :sub-text="'Tempo médio Cancelamento (dias): ' + cards.tempoMedioCancelamento" />
        <CardLoadComponent v-if="card2_loading" />
      </v-col>

      <v-col cols="12" sm="4" lg="4">
        <base-material-stats-card v-if="!card3_loading" color="orange" icon="mdi-alert-circle"
          title="Motivo Cancelamento Comum" sub-icon-color="red" :valuex='false'
          :value="`${cards.motivoMaisComum && cards.motivoMaisComum !== '' ? cards.motivoMaisComum + ' - ' : ''}${cards.qtdMotivoMaisComum}`"
          :sub-text="'Idade Média: ' + cards.idadeMediaCancelados" />
        <CardLoadComponent v-if="card3_loading" />
      </v-col>
    </v-row>


    <v-container style="background-color:#eee" id="contrato" fluid tag="section">
      <v-row>
        <v-col cols="12">
          <base-material-card icon="mdi-cart-arrow-up" title="Retenção de Vendas" color="green"
            style="margin-top:20px !important; padding-top:10px !important" class="px-5 py-4">
            <v-card class="px-4 py-4">
              <v-card-title class="titulo-retencao">
                Retenção após 3 meses
              </v-card-title>

              <v-card-text>
                <v-data-table style="margin-top: 1rem; min-height: 30vh;" :headers="headers" :items="Vendas3meses"
                  :items-per-page="50" items-per-page-text="Linhas por página" class="elevation-0 text-center">

                  <template v-slot:top>
                    <v-progress-linear v-if="isLoading3" indeterminate color="rgb(71 130 180)"></v-progress-linear>
                  </template>

                  <template v-slot:item="row">
                    <tr :class="{ 'cor-row': row.index % 2 !== 0 }">
                      <td class="linha" style="font-size: 12px;">
                        {{ row.item.periodo }}
                      </td>
                      <td class="linha">
                        {{ row.item.vendas }}
                      </td>

                      <td class="linha">
                        {{ row.item.cancelados }}
                      </td>
                      <td class="linha">
                        {{ row.item.ativos }}
                      </td>
                      <td class="linha">
                        {{ row.item.emDia }}
                      </td>
                      <td class="linha">
                        {{ row.item.atraso1p }}
                      </td>
                      <td class="linha">
                        {{ row.item.atraso2p }}
                      </td>
                      <td class="linha">
                        {{ row.item.atraso3p }}
                      </td>
                      <td class="linha">
                        {{ row.item.atraso4p }}
                      </td>
                      <td class="linha">
                        {{ row.item.atraso5p }}
                      </td>
                      <td class="linha">
                        {{ row.item.atraso6p }}
                      </td>
                      <td class="linha">
                        {{ row.item.totalInad }}
                      </td>
                      <td class="linha">
                        {{ row.item.pctIndad }}%
                      </td>
                      <td class="linha">
                        {{ row.item.pctAtivos }}%
                      </td>
                      <td>
                        <div @click="download_xls(row.item.periodo, 3)" title="Fazer Download do Arquivo"
                          style="color: green; font-size: 20px; cursor: pointer; display: flex; align-items: center; justify-content: center; width: 100%; height: 100%;">
                          <i class="fas fa-file-excel"></i>
                        </div>
                      </td>


                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>

            </v-card>
            <v-card class="px-4 py-4">
              <v-card-title class="titulo-retencao">
                Retenção após 6 meses
              </v-card-title>
              <v-card-text>
                <v-data-table style="margin-top: 1rem; min-height: 30vh;" :headers="headers" :items="Vendas6meses"
                  :items-per-page="50" items-per-page-text="Linhas por página" class="elevation-0 text-center">

                  <template v-slot:top>
                    <v-progress-linear v-if="isLoading6" indeterminate color="rgb(71 130 180)"></v-progress-linear>
                  </template>

                  <template v-slot:item="row">
                    <tr :class="{ 'cor-row': row.index % 2 !== 0 }">
                      <td class="linha" style="font-size: 12px;">
                        {{ row.item.periodo }}
                      </td>
                      <td class="linha">
                        {{ row.item.vendas }}
                      </td>

                      <td class="linha">
                        {{ row.item.cancelados }}
                      </td>
                      <td class="linha">
                        {{ row.item.ativos }}
                      </td>
                      <td class="linha">
                        {{ row.item.emDia }}
                      </td>
                      <td class="linha">
                        {{ row.item.atraso1p }}
                      </td>
                      <td class="linha">
                        {{ row.item.atraso2p }}
                      </td>
                      <td class="linha">
                        {{ row.item.atraso3p }}
                      </td>
                      <td class="linha">
                        {{ row.item.atraso4p }}
                      </td>
                      <td class="linha">
                        {{ row.item.atraso5p }}
                      </td>
                      <td class="linha">
                        {{ row.item.atraso6p }}
                      </td>
                      <td class="linha">
                        {{ row.item.totalInad }}
                      </td>
                      <td class="linha">
                        {{ row.item.pctIndad }}%
                      </td>
                      <td class="linha">
                        {{ row.item.pctAtivos }}%
                      </td>
                      <td>
                        <div @click="download_xls(row.item.periodo, 6)" title="Fazer Download do Arquivo"
                          style="color: green; font-size: 20px; cursor: pointer; display: flex; align-items: center; justify-content: center; width: 100%; height: 100%;">
                          <i class="fas fa-file-excel"></i>
                        </div>
                      </td>


                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>

            </v-card>
            <v-card class="px-4 py-4">
              <v-card-title class="titulo-retencao">
                Retenção após 12 meses
              </v-card-title>
              <v-card-text>
                <v-data-table style="margin-top: 1rem; min-height: 30vh;" :headers="headers" :items="Vendas12meses"
                  :items-per-page="50" items-per-page-text="Linhas por página" class="elevation-0 text-center">

                  <template v-slot:top>
                    <v-progress-linear v-if="isLoading12" indeterminate color="rgb(71 130 180)"></v-progress-linear>
                  </template>

                  <template v-slot:item="row">
                    <tr :class="{ 'cor-row': row.index % 2 !== 0 }">
                      <td class="linha" style="font-size: 12px;">
                        {{ row.item.periodo }}
                      </td>
                      <td class="linha">
                        {{ row.item.vendas }}
                      </td>

                      <td class="linha">
                        {{ row.item.cancelados }}
                      </td>
                      <td class="linha">
                        {{ row.item.ativos }}
                      </td>
                      <td class="linha">
                        {{ row.item.emDia }}
                      </td>
                      <td class="linha">
                        {{ row.item.atraso1p }}
                      </td>
                      <td class="linha">
                        {{ row.item.atraso2p }}
                      </td>
                      <td class="linha">
                        {{ row.item.atraso3p }}
                      </td>
                      <td class="linha">
                        {{ row.item.atraso4p }}
                      </td>
                      <td class="linha">
                        {{ row.item.atraso5p }}
                      </td>
                      <td class="linha">
                        {{ row.item.atraso6p }}
                      </td>
                      <td class="linha">
                        {{ row.item.totalInad }}
                      </td>
                      <td class="linha">
                        {{ row.item.pctIndad }}%
                      </td>
                      <td class="linha">
                        {{ row.item.pctAtivos }}%
                      </td>
                      <td>
                        <div title="Fazer Download do Arquivo" @click="download_xls(row.item.periodo, 12)"
                          style="color: green; font-size: 20px; cursor: pointer; display: flex; align-items: center; justify-content: center; width: 100%; height: 100%;">

                          <i class="fas fa-file-excel"></i>
                        </div>
                      </td>


                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>

            </v-card>

          </base-material-card>
          <br />

        </v-col>
      </v-row>


    </v-container>

  </v-app>
</template>

<script>
import FiltroComponent from "./components/FiltroRetencao.vue";
import Financeiro from "../../services/financeiro";
import CardLoadComponent from "./components/CardLoad.vue";

export default {
  name: 'Cobranca',

  components: {
    FiltroComponent,
    CardLoadComponent
  },

  data() {
    return {
      card1_loading: false,
      card2_loading: false,
      card3_loading: false,
      Vendas3meses: [],
      Vendas6meses: [],
      Vendas12meses: [],
      headers: [
        { text: 'Mês', value: 'periodo', align: 'center' },
        { text: 'Vendas', value: 'vendas', align: 'center' },
        { text: 'Cancelados', value: 'cancelados', align: 'left' },
        { text: 'Ativos', value: 'ativos', align: 'center' },
        { text: 'Em dia', value: 'emDia', align: 'center' },
        { text: 'Atraso 1p', value: 'atraso1p', align: 'center' },
        { text: 'Atraso 2p', value: 'atraso2p', align: 'center' },
        { text: 'Atraso 3p', value: 'atraso3p', align: 'center' },
        { text: 'Atraso 4p', value: 'atraso4p', align: 'center' },
        { text: 'Atraso 5p', value: 'atraso5p', align: 'center' },
        { text: 'Atraso 6p', value: 'atraso6p', align: 'center' },
        { text: 'Total Inadimplentes', value: 'totalInad', align: 'center' },
        { text: 'Inadimplentes', value: 'pctIndad', align: 'center' },
        { text: 'Retenção', value: 'pctAtivos', align: 'center' },
        { text: '', value: '', sortable: false, align: 'center' },
      ],
      search: "",
      selectedCompany: 'Previda',
      selectedYear: '2024',
      showFilter: false,
      empresas: ["CEMITERIO PREVIDA", "MEMORIAL PARQUE MACEIÓ", "PARQUE DO AGRESTE", "PREVIDA", "A FLOR DO CAFÉ"],
      showProgress: false,
      isLoading3: false,
      isLoading6: false,
      isLoading12: false,
      cards: {
        "vendas": 0,
        "ativas": 0,
        "canceladas": 0,
        "tempoMedioCancelamento": 0,
        "motivoMaisComum": "",
        "qtdMotivoMaisComum": 0,
        "idadeMediaCancelados": 0
      }
    };

  },

  methods: {

    filterData(valores) {

      if (this.selectedCompany === '' || this.selectedYear === '') return;

      this.selectedYear = valores.selectedYear;
      this.selectedCompany = valores.selectedCompany;
      this.consultarVendas(this.selectedCompany, this.selectedYear)

    },
    consultarVendas(empresa, ano) {
      this.isLoading3 = true; this.isLoading6 = true; this.isLoading12 = true;
      this.card1_loading = true; this.card2_loading = true; this.card3_loading = true;
      const primeiroDiaAno = `${ano}-01-01`;

      Financeiro.listaCardsRetencao(ano, empresa)
        .then(response => {
          this.cards = response.data
          console.log(this.cards)
        }).catch(error => {
          console.error("Erro ao buscar os dados:", error);
        }).finally(
          this.card1_loading = false, this.card2_loading = false, this.card3_loading = false
        );

      Financeiro.vendasRetencao(primeiroDiaAno, empresa, 3)
        .then(response => {
          this.Vendas3meses = response.data;
        })
        .catch(error => {
          console.error("Erro ao buscar os dados:", error);
        }).finally(
          this.isLoading3 = false
        );

      Financeiro.vendasRetencao(primeiroDiaAno, this.selectedCompany, 6)
        .then(response => {
          this.Vendas6meses = response.data;
        })
        .catch(error => {
          console.error("Erro ao buscar os dados:", error);
        }).finally(
          this.isLoading6 = false
        );
      Financeiro.vendasRetencao(primeiroDiaAno, this.selectedCompany, 12)
        .then(response => {
          this.Vendas12meses = response.data;
        })
        .catch(error => {
          console.error("Erro ao buscar os dados:", error);
        }).finally(
          this.isLoading12 = false
        );
    },
    download_xls(periodo, periodicidade) {
      this.showProgress = true;

      const [mes, ano] = periodo.split("/");
      const primeiroDiaMes = `${ano}-${mes}-01`;

      Financeiro.listaVendasRetencao(primeiroDiaMes, this.selectedCompany, periodicidade)
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.error("Erro ao buscar os dados:", error);
        }).finally(
          this.showProgress = false
        );
    },


    dashboard() {

    }

  },
}

</script>

<style scoped>
.linha {
  font-size: 12px !important;
  font-weight: bold !important;
}

.cor-row {
  background-color: #90d34c21;
  transition: none !important;
}

.titulo-retencao {
  font-size: 0.8rem;
  font-weight: bold;
  color: #343F53;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 16px;
  text-align: center;
}

.v-progress-circular {
  margin: 1rem;
}

.custom-gray-background {
  height: 80px;
}
</style>
