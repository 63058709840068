<template>
  <v-container fluid style="margin: 0">
    <v-card
      elevation="0"
      style="margin-top: 0; margin-bottom: -30px; overflow: hidden; background-color: transparent"
      class="px-5 py-1"
    >
      <v-row
        class="custom-gray-background fill-width d-flex justify-start align-center"
        v-if="mostrarFiltro"
      >

        <v-col cols="12" sm="6" md="2">
          <span>Ano</span>
          <v-select
            v-model="selectedYear"
            :items="years"
            label="Ano"
            dense
            solo-inverted
          ></v-select>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <span>Empresa</span>
          <v-select
            v-model="selectedCompany"
            :items="empresas"
            label="Empresa"
            dense
            solo-inverted
          ></v-select>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-card-actions class="justify-end">
        <v-btn
          @click="alternarVisibilidade"
          color="#4682B4"
          small
          class="mr-2"
          style="font-size: 14px; font-weight: bold"
        >
          {{ mostrarFiltro ? "Ocultar Filtro" : "Mostrar Filtro" }}
          <v-icon style="margin-left: 8px" dark>
            {{ mostrarFiltro ? "mdi-eye-off" : "mdi-eye" }}
          </v-icon>
        </v-btn>

        <v-btn
          @click="limparFiltro"
          v-if="mostrarFiltro"
          color="#4682B4"
          small
          outlined
          class="mr-2"
          style="font-size: 14px; font-weight: bold"
        >
          Limpar
        </v-btn>
        <v-btn
          @click="filtrar"
          v-if="mostrarFiltro"
          color="#4682B4"
          small
          style="font-size: 14px; font-weight: bold"
        >
          Filtrar
          <v-icon style="margin-left: 8px" dark>mdi-filter</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: {
    opcoes: { type: Array, default: () => [] },
    modos: { type: Array, default: () => [] },
    tipos: { type: Array, default: () => [] },
    title_opcao: { type: String, default: "" },
  },

  data() {
    return {
      mostrarFiltro: true,
      selectedYear: new Date().getFullYear() -1,
      selectedCompany: "PREVIDA", 
      selectedType: "Todos",
      selectedOpcao: "Todas",
      selectMode: "",
      months: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      years: Array.from({ length: 11 }, (_, i) => 2020 + i), // Anos de 2020 a 2030
      empresas: [
        "CEMITERIO PREVIDA",
        "MEMORIAL PARQUE MACEIÓ",
        "PARQUE DAS FLORES",
        "PARQUE DO AGRESTE",
        "PREVIDA",
        "A FLOR DO CAFÉ",
      ],
    };
  },

  computed: {
   
  },


  methods: {
    alternarVisibilidade() {
      this.mostrarFiltro = !this.mostrarFiltro;
    },
    limparFiltro() {
      this.selectedYear = new Date().getFullYear();
      this.selectedCompany = ["SELECIONE"];
    },
    filtrar() {
      this.$emit("filtrar", {
        selectedYear: this.selectedYear,
        selectedCompany: this.selectedCompany,
      });
    },
  },
};
</script>
